export default {
  id: 'cdek',
  debug: false,
  domain: 'cdek',
  title: 'Cdek',
  app: 'cdek',
  localServer: 'wms.ffcdek.ru',
  defaultServer: 'https://cdek.orderadmin.ru',
  webService: 'wss://centrifugo.orderadmin.eu/connection/websocket',
  securityLogo: 'assets/logoCdek.png',
  logoSrc: 'assets/logoCdek.png',
  logoLeft: 'assets/logoLeftCdek.png',
  logoTop: 'assets/logoTopCdek.png',
  theme: 'cdek',
  themes: [
    {
      name: 'Default',
      theme: 'default'
    },
    {
      name: 'Cdek',
      theme: 'cdek'
    }
  ],
  themeSettings: {
    default: {
      headerColor: 'bg-cdek',
      sidebarColor: 'bg-light',
      sidebarTextColor: 'text-dark',
    }
  },
  servers: [
    {
      name: 'Cdek',
      domain: 'cdek',
      host: 'https://panel.orderadmin.ru'
    },
    {
      name: 'Beta',
      domain: 'cdek',
      host: 'https://beta.orderadmin.ru'
    }
  ],
  domains: [
    {
      name: 'Cdek.ru',
      value: 'cdek'
    }
  ],
  locale: {
    name: 'Русский',
    locale: 'ru',
    flag: 'ru'
  },
  deliveryServices: {
    filter: []
  },
  calculator: {
    deliveryServices: [{ id: 1 }]
  },
  rates: {
    filter: [
      {
        type: 'eq',
        field: 'deliveryService',
        value: 1
      }
    ]
  },
  locality: {
    filter: []
  },
  settings: {
    cdek: {
      accounts: {
        contractor: 465,
        currency: 1,
        rates: {
          deposit: 29
        }
      },
      'delivery-request': {
        'delivery-services-request': true,
        'delivery-services-request-data': {
          'deliveryService': 1,
          'rate': 49,
          'servicePoint': null,
          'currency': 1
        }
      }
    }
  },
  storage: {
    parents: [
      {
        name: 'Новосибирск',
        id: 861
      },
      {
        name: 'Новосибирск',
        id: 861
      }
    ]
  },
  defaultDimensions: {
    weight: 1000,
    dimensions: {
      x: 100,
      y: 100,
      z: 100
    }
  },
  wizardTabs: {
    warehouse: {
      state: 'active',
      type: 'virtual'
    }
  },
  wizardBannedRoles: [
    3,
    29
  ],
  wizardAccessRoles: [
    11
  ],
  isRequiredDeliveryRequest: true,
  automaticCreatedEntities: {
    cdek: {
      sender: {},
      shop: {},
      legalEntity: {
        state: 'active',
        type: 'customer'
      },
      depositAccount: {
        type: 'deposit',
        contractor: 465,
        creditLimit: 0,
        rate: 29,
        currency: 1
      }
    }
  }
}
